// TODO: remove
// This is redundant with the bugsnag init in engines/dash-fe but
// required until we sunset legacy dashboard

import Bugsnag, { NotifiableError, OnErrorCallback, Event, BrowserConfig } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import * as React from 'react';

let BugsnagErrorBoundary: React.ComponentType<{
  children?: any;
  onError?: OnErrorCallback;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
    clearError: () => void;
  }>;
}> = ({ children }) => children

declare global {
  interface Window {
    BUGSNAG_API_KEY: string
    RELEASE_STAGE: string
    BUGSNAG_ENABLED_RELEASE_STAGES: string[];
    BUGSNAG_CURR_USER: { id?: string, email?: string, name?: string };
    APP_VERSION: string;
  }
}

const bugsnagApiKey = window.BUGSNAG_API_KEY
const bugsnagEnabled = !!bugsnagApiKey

if (bugsnagEnabled) {
  const initProps: BrowserConfig = {
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: window.BUGSNAG_ENABLED_RELEASE_STAGES,
    releaseStage: window.RELEASE_STAGE,
    appVersion: window.APP_VERSION,
    user: window.BUGSNAG_CURR_USER
  }

  Bugsnag.start(initProps)

  BugsnagPerformance.start({ apiKey: window.BUGSNAG_API_KEY })

  BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
} else {
  console.warn('Bugsnag API key not found, skipping Bugsnag initialization')
}

export const notifyBugsnag = (error: NotifiableError, onError?: OnErrorCallback, postReportCallback?: (err: any, event: Event) => void) => {
  if (bugsnagEnabled) {
    console.log('Notifying Bugsnag of error', error)
    Bugsnag.notify(error, onError, postReportCallback)
  } else {
    console.log('Bugsnag API key not found, skipping Bugsnag notification')
    console.error(error)
  }
}

export { BugsnagErrorBoundary }
