// ex: errorEvent('join error', 'bad_cvv')
export function errorEvent(category, action, label) {
  if (window.ga) {
    window.ga('send', 'event', category, action, label);
  }
}

export function purchaseEvent(cartID, totalAmount, payMethod) {
  // payMethod notes if apple or google pay
  if (window.ga) {
    window.ga('ec:setAction', 'purchase', {
      id: cartID,
      revenue: totalAmount,
      option: payMethod
    });
    window.ga('send', 'event', 'ecommerce', 'purchase', {
      nonInteraction: true
    });
  }
}
